@use "sass:math";

// specific selectors or individual overrides
.accessibility-hidden {
  position: absolute;
  top: auto;
  left: -10000px;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

.image_left,
.left {
  float: left;
  margin: 0 math.div($base-font-size, 2);
}

.image_right,
.right {
  float: right;
  margin: 0 math.div($base-font-size, 2);
}

img[style*="float"] {
  margin: 0 math.div($base-font-size, 2);
}

blockquote {
  border: none !important;
}

.b4,
.b6 {
  h2,
  h3,
  h4,
  h5,
  .h2,
  .h3,
  .h4,
  .h5 {
    line-height: normal;
  }
}

#emergency-banner,
#dartmouth-emergency-banner {
  margin-bottom: 0;
}

#emergency-banner #msg-blk {
  font-size: 1.2em;
}

#global-mobile-menu {
  top: 56px;
}

table.hours {
  @extend .dart-table;
  @extend .dart-table-striped;
}

#b-content {
  @include make-sm-column(9);
  @include make-sm-column-push(3);
}

.b1 {
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
}

.b6 form {
  @extend .form-inline;
}

.b6 input,
.b6 textarea {
  @extend .form-control;
}

.b6 label {
  @extend .control-label;
}

.b6 input[type="submit"] {
  @include button-default;

  color: $white;
  background-color: $dart-green;
  &:hover {
    color: $white !important;
    background-color: $black;
  }
}

// dataTables
.dataTable img,
.datatable img {
  display: block;
  max-width: 100% !important;
  height: auto !important;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd,
table.dataTable tbody tr.odd {
  background-color: $off-white !important;
}

table.dataTable.stripe tbody tr:hover,
table.dataTable.display tbody tr:hover,
table.dataTable tbody tr:hover {
  background-color: $gray-medium !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child::before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child::before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child::before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child::before {
  background-color: $gray-dark !important;
}

.b6 ul.pagination li::before {
  content: "" !important;
}

// breadcrumbs
.b1 {
  display: none;
}

.breadcrumbs {
  margin-top: 15px;
  margin-left: 15px;
  font-size: $base-font-size * 0.8;
  a {
    color: $white;
    border-bottom: 0.5px solid;
    font-weight: 200;
    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;
      border-bottom-color: $spring-green;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  .page-title {
    font-weight: 500;
  }
  .breadcrumbs-spacer {
    &::before,
    &::after {
      margin: 0 2px;
      content: "";
    }
  }
}

#b-content {
  display: grid;
  grid-template-rows: repeat(7, fit-content(100%));
  grid-template-columns: 1fr fit-content(35%);
  gap: 0 18px;
  > .cleardiv {
    display: none;
  }
}

.b1,
.b2,
.b3,
.b5,
.b6,
.b7,
.b8,
.b9 {
  grid-column: 1;
}

.b2 {
  grid-row: 1;
}
.b3 {
  grid-row: 3;
}
.b4 {
  grid-row: 1 / span 8;
  grid-column: 2;
}
.b5 {
  grid-row: 4;
}
.b6 {
  grid-row: 2;
}
.b7 {
  grid-row: 5;
}
.b8 {
  grid-row: 6;
}
.b9 {
  grid-row: 7;
}

// page title
.b2 {
  h1 {
    margin: 0 0 $base-font-size * 0.5 0;
  }
}

// content areas
.b4,
.b6 {
  padding-left: 0;
  color: $black;
  font-family: $font-family-base;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
  #myGalleria {
    box-shadow: none;
  }
  a {
    @include border-bottom-link;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: auto;
  }

  // see _list.scss .dcl-content-region ul rules
  ul {
    list-style-type: none;
    li::before {
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      content: "\25E6";
      color: #328060;
      line-height: 1.9em;
    }
  }
  nav ul,
  ul.nav,
  #image-list {
    li::before {
      content: "";
    }
  }
}

// callout box
.b4 {
  width: auto;
  margin: 0;
  padding: 0;
  .b4-wrapper {
    background-color: $off-white;
  }
  h1,
  h2,
  h3 {
    margin: 10px 0;
    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
  }
  ul {
    padding-left: 0;
    list-style: none;
    li::before {
      display: none;
    }
  }
}

// main content
.b6 {
  a[rel="thumbnail"] {
    img {
      float: left;
      margin-right: 16px;
    }
  }

  #content {
    display: table;
    float: none !important;
    width: 100% !important;
    height: auto !important;
    margin: $base-font-size 0;
  }

  p {
    padding: 0 !important;
  }

  td {
    padding: math.div($base-font-size, 4);
  }
}

// side nav
// see _menu.scss .region-sidebar-first
#c-content {
  @include make-sm-column(3);
  @include make-sm-column-pull(9);
  .c1 {
    margin-bottom: $base-font-size;
    border-collapse: collapse;
    font-family: $font-family-base;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
    h1,
    h2:first-of-type,
    h3:first-of-type {
      margin-top: 0;
    }
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 10px $base-font-size 10px 0;
      text-transform: none;
      font-family: $font-family-base;
      font-size: $base-font-size * 1.25;
      font-weight: bold;
      .font-family-sans-serif-loaded & {
        font-family: $font-family-sans-serif !important;
      }
      a {
        color: $black !important;
      }
    }
    h1 {
      line-height: inherit !important;
    }
    ul {
      @extend .menu;
      @extend .nav;
    }
    ul {
      li {
        background-color: $college-background-gray;
        font-size: em($base-font-size);
        &:last-of-type {
          border-bottom: none;
        }
        &.active {
          background-color: $spring-green;
        }
        a {
          padding-left: $base-font-size * 0.5;
          color: $dart-green;
          &:hover,
          &:focus {
            background-color: $dart-green !important;
          }
        }
        ul {
          li {
            border-bottom: none !important;
            &:last-of-type {
              padding-bottom: $base-font-size * 0.5;
            }
          }
          a {
            padding: $base-font-size * 0.25 $base-font-size * 0.5
              $base-font-size * 0.25 $base-font-size * 1.5;
            border-bottom: none !important;
            font-size: $base-font-size * 0.85;
          }
        }
        a {
          border-collapse: collapse;
        }
      }
    }
  }

  .c2,
  .c3,
  .c4,
  .exhibits {
    margin-bottom: $base-font-size;
    text-align: left;
    border-collapse: collapse;
    font-family: $font-family-base;
    font-size: $base-font-size * 0.9;
    font-weight: normal;
    .font-family-sans-serif-loaded & {
      font-family: $font-family-sans-serif !important;
    }
    h2,
    h3 {
      margin-top: $base-font-size * 0.5;
      border: none;
      font-family: $font-family-base;
      font-size: $base-font-size * 1.15;
      font-weight: normal;
      .font-family-sans-serif-loaded & {
        font-family: $font-family-sans-serif !important;
      }
    }
    ul {
      padding-left: 0;
      list-style: none;
      li {
        border: none;
        font-size: $base-font-size * 0.8;
      }
    }
    a {
      @include link(!important);

      padding-left: 0;
      text-transform: none;
      border-color: $dart-green !important;
      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $spring-green !important;
        background-color: transparent !important;
        &::after {
          content: "";
        }
      }
      &:focus {
        outline: solid 3px $bsa-outline-color !important;
      }
      &.stafflink {
        color: $gray-dark !important;
        border-color: transparent !important;
        font-weight: normal;
        &:hover {
          text-decoration: none !important;
          color: $gray-dark !important;
          background-color: transparent !important;
          &::after {
            content: "";
          }
        }
        &:focus {
          background-color: transparent !important;
          &::after {
            content: "";
          }
        }
      }
    }
  }
  .exhibits {
    p {
      display: grid;
      grid-template-columns: fit-content(100%) fit-content(100%);
    }
    a {
      display: inline-block;
      text-decoration: underline solid $dart-green !important;
      &:hover,
      &:focus {
        color: $dart-green !important;
        text-decoration-color: $spring-green !important;
      }
    }
  }
}

footer {
  padding-bottom: math.div($base-font-size, 2);
  .region-footer-copyright {
    margin: 2em 0 0 0;
    padding: 10px 10px 0 10px;
  }
}

p.last-updated {
  max-width: 100%;
  margin: math.div(-$base-font-size, 2) 0 0 0;
  padding: 0 15px 18px 0;
  text-align: center;
  color: $white;
  background-color: $forest-green;
  font-family: $font-family-base;
  font-size: $base-font-size * 0.75;
  .font-family-sans-serif-loaded & {
    font-family: $font-family-sans-serif;
  }
  a {
    display: inline-block;
    margin-bottom: 4px;
    padding: 2px 0;
    color: $white;
    line-height: em(16px);
    &:hover,
    &:focus {
      margin-bottom: 3px !important;
      text-decoration: none;
      color: $white;
      border-bottom: 1px solid $spring-green;
      background: none;
    }
  }
}
.hero-menu-wrapper {
  display: none;
}
.basic-hero-wrapper {
  .hero-menu-wrapper {
    display: block;
  }
  .basic-hero-background {
    background: none;
  }
}

@media (max-width: em(767px)) {
  #emergency-banner,
  #dartmouth-emergency-banner {
    font-size: 0.9em;
  }

  #c-content {
    margin-right: -15px !important;
    margin-left: -15px !important;
    padding: 0 !important;
    .c1 {
      display: none;
    }
    .c2,
    .c3,
    .c4 {
      padding-left: 5px;
    }
    .c4 {
      padding-top: 18px;
    }
    .dcl-sidebar-first {
      background-color: $off-white;
      .region-sidebar-first h2 {
        padding-left: 0;
      }
    }
  }
  #b-content {
    grid-template-rows: repeat (9, fit-content(100%));
    grid-template-columns: 1fr;
    .b4 {
      grid-row: 9;
      grid-column: 1;
    }
  }
  .b4 {
    float: none !important;
    max-width: 100% !important;
  }

  .b6 {
    float: none !important;
  }
  .b4,
  .b6 {
    #myGalleria {
      float: none;
    }
  }
  #primary-secondary-menus-wrapper {
    min-height: 56px;
  }
  .hero-menu-wrapper {
    display: block;
    padding-left: 0;
  }
}

@media (max-width: em(576px)) {
  h1 {
    line-height: 100%;
  }
}
