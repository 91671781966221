@use "sass:math";

// Omni Update specific styles

// declare some variable overrides for font paths
$base-font-path: "//library.dartmouth.edu/sitelib/";
$manuka-font-path: "#{$base-font-path}" !default;
$national-font-path: "#{$base-font-path}" !default;
$ruzicka-font-path: "#{$base-font-path}" !default;
$glyphicons-font-path: "#{$base-font-path}bootstrap-3.3.4/fonts/";
$dart-sprite-path: "sprite-dart.png" !default;

:root {
  --fa-font-brands: normal 400 1em/1 "FontAwesome";
}

// import drupal core css that we will need
@import "drupal_core_for_outside_services";

// custom theme style
@import "drupal_core_base_styles";

// mixins based on bootstrap to prevent bloat
@import "dart_bootstrap_based_mixins";

// shared base styles
@import "ou_library_apps_base";

// fontawesome fixes
#dept-info ul li.social-links a[href^="https://www.facebook.com"]::before,
.icon-facebook::after {
  content: "\f09a";
}

// 404
.error-404-content {
  display: grid;
  grid-template-columns: fit-content(100%) 1fr;
  align-items: center;
  margin-top: 9px;
  .error-404-image {
    max-width: 225px;
  }
}

@media (max-width: em(535px)) {
  .error-404-content {
    grid-template-columns: 1fr;
    grid-row-gap: 18px;
  }
}

// subsite specific
// rauner
.communityImages {
  margin-right: math.div($base-font-size, 2);
}

// callout rss in OU
.b4 {
  div[id^="rssdiv"] .rss-content {
    margin: 0 !important;
  }
  div[id^="rssdiv"] a.rss-image-link {
    float: right;
  }
  div[id^="rssdiv"] img {
    border-color: $gray-light;
    border-radius: math.div($base-font-size, 4);
    background-color: $white;
  }

  div[id^="rssdiv"] .tr-caption-container {
    float: right;
  }

  div[id^="rssdiv"] .tr-caption {
    text-align: center;
    font-size: 0.8em;
    line-height: 1.1em;
  }

  div[id^="rssdiv"] .feedIconLink {
    display: none !important;
  }

  > * {
    padding: 0 20px;
  }
  > ul {
    padding-left: 20px;
  }
  > *:first-of-type {
    padding-top: 10px;
  }
  > *:last-of-type {
    padding-bottom: 10px;
  }
}

.b6 {
  form {
    input[type="radio"] {
      margin: 0 4px 2px 4px;
    }
    input[type="text"] {
      margin: 4px 0;
    }
  }

  // jPLayer
  .jp-jplayer {
    line-height: normal;
  }
  .jp-audio {
    .jp-type-playlist {
      div.jp-interface {
        height: 60px;
        ul.jp-controls {
          overflow: hidden;
          margin: 0 0 0 $base-font-size;
          padding: 0;
          list-style-type: none;
        }
        ul.jp-controls,
        ul.jp-toggles {
          li {
            &::before {
              display: inline;
              margin-left: 0;
              content: "";
            }
          }
          a {
            display: block;
            overflow: hidden;
            text-indent: -9999px;
          }
        }
        .jp-toggles {
          position: absolute;
          top: 30px;
          left: 327px;
          width: 60px;
          margin: 0;
        }

        ul.jp-toggles {
          li {
            a {
              width: 26px;
              height: 18px;
            }
          }
        }
        .jp-progress {
          position: absolute;
          top: 14px;
          height: 15px;
        }
        .jp-volume-bar {
          position: absolute;
          top: 18px;
          left: 330px;
          overflow: hidden;
          width: 46px;
          height: 5px;
          cursor: pointer;
        }

        .jp-mute,
        .jp-unmute,
        .jp-volume-max {
          position: absolute;
          top: 13px;
          left: 307px;
          width: 18px;
          height: 15px;
        }
        a.jp-pause {
          display: none;
          width: 40px;
          height: 40px;
          background: url("/library/_permacode/global/jQuery.jPlayer.2.1.0/image/jplayer.blue.monday.jpg")
            0 -42px no-repeat;
        }

        a.jp-unmute {
          display: none;
          background: url("/library/_permacode/global/jQuery.jPlayer.2.1.0/image/jplayer.blue.monday.jpg") -60px -170px
            no-repeat;
        }

        a.jp-shuffle-off {
          display: none;
          background: url("/library/_permacode/global/jQuery.jPlayer.2.1.0/image/jplayer.blue.monday.jpg") -60px -270px
            no-repeat;
        }
        a.jp-repeat-off {
          display: none;
          background: url("/library/_permacode/global/jQuery.jPlayer.2.1.0/image/jplayer.blue.monday.jpg") -60px -290px
            no-repeat;
        }

        .jp-volume-max {
          left: 390px;
        }

        .jp-shuffle {
          margin-left: 0;
        }
      }
    }
  }

  div.jp-video ul.jp-controls,
  div.jp-interface ul.jp-controls li,
  div.jp-video ul.jp-toggles,
  div.jp-interface ul.jp-toggles li {
    display: inline;
    float: left;
  }
}

#c-content {
  // rauner specific
  #rauner-social-icons,
  .social-icons {
    a:focus {
      display: inline-block;
      outline-offset: -3px;
    }
    img {
      @extend .img-responsive;

      display: inline;
      max-width: $base-font-size * 2;
    }
  }
}

// alerts and general info box snippet
.subsite-alert {
  .subsite-alert-content {
    p {
      a {
        @extend %pill-link;
        &::before {
          @extend %icon-info;
          @extend %icon-style;

          display: inline-block;
          margin-right: 4px;
          font-size: $base-font-size * 1.5;
        }
      }
    }
  }
}

@media (max-width: em(565px)) {
  .b6 {
    .jp-audio {
      .jp-type-playlist {
        div.jp-interface {
          width: 170px;
          height: 110px !important;
          margin: 10px auto;

          .jp-progress {
            position: relative;
            top: 8px;
            left: 16px !important;
          }

          .jp-volume-bar {
            position: relative;
            top: 20px;
            left: 56px;
          }

          .jp-mute,
          .jp-unmute {
            position: absolute;
            top: 68px;
            left: 28px;
          }

          .jp-volume-max {
            position: absolute;
            top: 68px;
            left: 115px;
          }

          .jp-toggles {
            top: 86px;
            left: 53px;
          }

          .jp-current-time {
            position: absolute;
            top: 87px;
          }

          .jp-duration {
            position: absolute;
            top: 88px;
            left: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: em(565px)) {
  .b4 {
    div[id^="rssdiv"] img {
      max-width: 120px;
    }
  }
}
